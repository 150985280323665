import SlideImage1 from '../pages/home/assets/image/slide_1.jpg'
import SlideImage2 from '../pages/home/assets/image/slide_2.jpg'
import SlideImage3 from '../pages/home/assets/image/slide_3.jpg'
import SlideImage4 from '../pages/home/assets/image/slide_4.jpg'

export const imageSliderData = [
  { imageUrl: SlideImage1, description: "slider image" },
  { imageUrl: SlideImage2, description: "slider image" },
  { imageUrl: SlideImage3, description: "slider image" },
  { imageUrl: SlideImage4, description: "slider image" }
]
